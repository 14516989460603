import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FaAngleLeft } from "react-icons/fa";
import {
  findServiceStatus,
  getBtnInfo,
  getLatestStatus,
  handleUpdate,
  onInitialLoad,
} from "./subC/helperFunctions";
import CommonSkeleton from "../../skeletons/CommonSkeleton";
import { TiWarning } from "react-icons/ti";
import EarningSummary from "./subC/earningSummary/EarningSummary";
import PickUpComp from "./subC/pickup/PickUpComp";
import { AnimatePresence } from "framer-motion";
import SantaTeam from "./subC/santaTeam/SantaTeam";
import MaterialsList from "./subC/rawMaterial/MaterialsList";
import OutForService from "./subC/outForService/OutForService";
import WarningModalv2 from "../../../assets/WarningModalv2";
import LiveOrderIdx from "./liveOrder/LiveOrderIdx";
import WorkInfoContainer from "./scheduledStatus/WorkInfoContainer";
import AuthContext from "../../../context/AuthContext";
import usePullToRefresh, {
  RefreshLoader,
} from "../../../hooks/usePullToRefresh";
import Header2 from "../../../assets/headers/Header2";
import PastOrder from "./pastOrder/PastOrder";
import OrderSummaryContainer from "./liveOrder/subC/orderSummary/OrderSummaryContainer";
import OrderSummaryIdx from "./liveOrder/subC/orderSummary/OrderSummaryIdx";
import SlideToConfirmButton from "../../../assets/buttons/SlideToConfirmButton";
import SantaTeamManagementModal from "./subC/santaTeam/SantaTeamManagementModal";
import { BiCopy } from "react-icons/bi";

import moment from "moment-timezone";
import TeamSelectionModal from "./subC/TeamManagementModal";
import OrderCompletionPage from "./liveOrder/subC/afterOrderCompletion/OrderCompletionPage";

// Set default timezone to Asia/Kolkata
moment.tz.setDefault("Asia/Kolkata");

export default function OrderIndex() {
  const { orderId } = useParams();
  const nav = useNavigate();
  const {
    initialState: { santaId, santa },
  } = useContext(AuthContext);

  const containerRef = useRef(null);

  const [refereshStates, setRefereshStates] = useState({});

  usePullToRefresh(
    containerRef,
    () => onInitialLoad({ santaId, orderId, setStates, states }),
    (params) => {
      setRefereshStates(params);
    },
    {
      isGradient: false,
      loaderColor: "#2136d4",
    }
  );

  const [states, setStates] = useState({
    isLoadingOrder: true,
    isPickupPage: false,
    order: null,
    santaIndexInSantaGroup: null,
    button: { btnText: "", fun: () => {}, disabled: false, subText: "" },
    currStatus: "", //"materialPacked", "materialPicked", "onTheWay", "santaArived", "serviceStared", "serviceEnd", "santaLeaved"
    isCurrStatusPageRender: false,
    loadingCurrentLocation: false,
    santaTeamManagementModal: false, // will open the modal to handle the santa team for this order
    warningModel: {
      msg: "",
      leftBtnText: "",
      rightBtnText: "",
      isRender: false,
      funOnLeftBtn: () => {},
      funOnRightBtn: () => {},
    },
    finalAmount: "",
  });

  useEffect(() => {
    window.updateLocation();
    onInitialLoad({ santaId, orderId, setStates, states, nav });

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      ["onTheWay", "santaArrived", "otpVerified"].includes(states.currStatus)
    ) {
      // satisfies the condition of out for service
      setStates((p) => ({ ...p, isCurrStatusPageRender: true }));
    }
  }, [states.currStatus]);

  useEffect(() => {
    if (states.order) {
      let currStatus = getLatestStatus({
        bookingStatus: states.order?.bookingStatus,
        orderServiceStatus: states.order?.orderServiceStatus,
      });

      const { btnTxt, fun, disabled, subText } = getBtnInfo({
        order: states.order,
        setStates: setStates,
        states: states,
        bookingStatus: states.order?.bookingStatus,
        currServiceStatus: currStatus,
      });

      setStates((p) => ({
        ...p,
        button: {
          btnText: btnTxt,
          fun: fun,
          disabled: disabled,
          subText,
        },
        currStatus: currStatus,
      }));
    }

    // eslint-disable-next-line
  }, [states.order, states.isCurrStatusPageRender]);

  const updateOrder = useCallback(
    async (updateObject) => {
      try {
        await handleUpdate({
          orderId: states.order?._id,
          updateObject,
          arrayFilters: null,
          setStates,
          isOrderCompletionProcess: false,
          tags: [],
        });
      } catch (error) {
        console.error(error);
      }
    },
    [states.order?._id]
  );

  const leaderSanta = useMemo(() => {
    return states.order?.santaGroups?.santas?.find((santa) =>
      santa.tags.includes("leader")
    );
  }, [states.order?.santaGroups?.santas]);

  if (!states.isLoadingOrder && !states.order) {
    return (
      <div className=" fixed  pt-[12.5rem] text-[.875rem] top-0 right-0 left-0 bottom-0 bg-blue-50 flex flex-col  ">
        <div className="fixed items-center justify-center flex flex-col top-0 right-0 left-0 rounded-b-xl blue-gradient p-4 py-6">
          <FaAngleLeft
            onClick={() => nav(-1)}
            className=" fixed top-6 left-4 text-[1.5rem]  "
          />
          <div className="flex mt-4 w-full justify-center text-[white]">
            <div className="flex flex-col font-[500] text-[1rem] items-center">
              <TiWarning className="text-[6rem]" />
              <div className=" ">Something went wrong</div>
              <div
                onClick={() =>
                  onInitialLoad({ santaId, orderId, setStates, states })
                }
                className="  rounded-full px-2 mt-2 bg-[#f0f5fe76]  text-[white]"
              >
                Try again
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (states.isLoadingOrder) {
    return (
      <div className="flex flex-col justify-center items-center gap-4 h-[100vh]">
        <div className="loader w-[2rem]" />
        <p>Loading order status...</p>
      </div>
    );
  }

  if (states.isPastOrder && !states.isCompleted) {
    // if the order is not for today
    return <PastOrder order={states.order} />;
  }

  if (
    findServiceStatus(states.order.orderServiceStatus, "serviceStarted")
      ?.child &&
    !findServiceStatus(states.order.orderServiceStatus, "serviceEnd")?.child
  ) {
    return (
      <>
        <LiveOrderIdx
          onReferesh={() =>
            onInitialLoad({ santaId, orderId, setStates, states })
          }
          santaId={santaId}
          updateOrder={updateOrder}
          order={states.order}
          parentStates={states}
          setParentStates={setStates}
          leaderSanta={leaderSanta}
        />
      </>
    );
  }

  if (states.order.bookingStatus === "Order Completed") {
    return (
      <OrderCompletionPage order={states.order} updateOrder={updateOrder} />
    );
  }

  return (
    <>
      <WarningModalv2 parentStates={states} setParentStates={setStates} />
      <SantaTeamManagementModal
        states={states}
        setStates={setStates}
        callBackFunc={async () => {
          await onInitialLoad({ santaId, orderId, setStates, states });
        }}
      />
      <Header2
        navFun={() => nav("/orders")}
        title={`#${states.order._id}`}
        order={states.order}
      />
      <div className="fixed top-0 left-0 right-0 bottom-0 ">
        <div
          ref={containerRef}
          className="   text-[.875rem] pt-[4rem]  items-center  scroll-bar-remove  overflow-x-hidden overflow-y-scroll h-full pb-[10rem] bg-blue-50 flex flex-col"
        >
          <RefreshLoader refereshStates={refereshStates} />

          <WorkInfoContainer order={states.order} />
          <OrderSummaryContainer setStates={setStates} order={states.order} />

          <div className="mt-6 w-[95%]">
            <div className="flex justify-between mb-2 items-center px-[2px] ">
              <p className=" text-[1rem] text-[gray] font-[500]">Materials</p>
              {(states.order?.bookingStatus === "Out for Service" ||
                states.currStatus === "materialPicked") && (
                <p
                  className="text-[#2136D4] text-[.875rem] font-medium"
                  onClick={() => {
                    setStates((p) => ({ ...p, isPickupPage: true }));
                  }}
                >
                  View
                </p>
              )}
            </div>
            {/* this is simply representing list , for pickup separate component is made */}
            <MaterialsList
              rawMaterials={states.order?.productId?.rawMaterials}
            />
          </div>

          {/* the leader can only see the earnings */}
          {leaderSanta?.santa?._id === santaId && (
            <>
              <div className="mt-6 w-[95%]">
                <p className="text-[1rem] text-[gray] gap-1 flex items-center font-[500] ">
                  Earning for{" "}
                  <span className="text-sm">#{states.order._id}</span>{" "}
                  <BiCopy
                    className="text-sm"
                    onClick={() => {
                      window.copyContent(states.order._id, "Order ID copied");
                    }}
                  />
                </p>
                <EarningSummary
                  adjustmentsArr={states?.order?.orderWallet || []}
                  santaHandlingFee={states.order?.santaGroups?.santaHandlingFee}
                  santaServiceCosting={
                    states.order?.santaGroups?.santaServiceCosting
                  }
                  order={states.order}
                  santaTip={states.order?.priceDetail?.santaTip}
                />
              </div>
            </>
          )}

          <div className="p-2 mt-6 fixed bottom-0 bg-[white] rounded-t-xl border-t border-blue-100 left-0 right-0 flex-col ">
            {leaderSanta?.santa?._id === santaId &&
              leaderSanta.tags.includes("optout") && (
                <div className="flex-col px-4 mb-3">
                  <div className="text-base font-semibold text-[#2136d4] ">
                    Opted out
                  </div>
                  <div className="text-xs text-gray-700 ">
                    You won’t be present; your team will cover this booking
                  </div>
                </div>
              )}

            <SlideToConfirmButton
              btnText={states.button.btnText}
              disabled={states.button.disabled}
              funOnFullSlide={states.button.fun}
              whyDisabledInfoText={states.button.subText}
            />
          </div>

          <div className="mt-6  w-[95%]">
            <div className=" w-full flex mb-4 items-center justify-between">
              <p className=" text-[1.125rem] w-full pl-1 font-[600] ">
                <span className="text-[gray]">Santa </span>
                <span className="   text-[black]">Team</span>
              </p>

              {leaderSanta?.santa?._id === santaId &&
                santa?.myTeam?.length > 0 && (
                  <div
                    onClick={() => {
                      window.floatingModal(
                        true,
                        <TeamSelectionModal
                          {...{
                            santa,
                            order: states.order,
                            refetchSantaDetails: () => {
                              onInitialLoad({
                                santaId,
                                orderId,
                                setStates,
                                states,
                              });
                            },
                          }}
                        />,
                        {
                          position: "c",
                          animation: "b-t",
                          animationLength: "70vh",
                        }
                      );
                    }}
                    className=" px-4 py-1 blue-gradient whitespace-nowrap rounded-lg cursor-pointer "
                  >
                    Manage team
                  </div>
                )}
            </div>
            <SantaTeam santaGroups={states.order?.santaGroups} />
          </div>

          {states.isLoadingOrder ? <CommonSkeleton /> : ""}
        </div>
      </div>
      {/* rest components which will render over the entire page conditionaly */}
      <AnimatePresence>
        {states.isPickupPage && (
          <PickUpComp
            parentStates={states}
            setParentStates={setStates}
            rawMaterials={states?.order?.productId?.rawMaterials || []}
          />
        )}
      </AnimatePresence>
      <OrderSummaryIdx
        order={states.order}
        states={states}
        setStates={setStates}
      />
      <AnimatePresence>
        {["onTheWay", "santaArrived", "otpVerified"].includes(
          states.currStatus
        ) &&
          states.isCurrStatusPageRender && (
            <OutForService
              MIN_DISTANCE_FOR_ARRIVE_INVALIDATION={
                states.order?.date?.delivery?.minDistanceOfSantaFromLocation
              }
              setParentStates={setStates}
              parentStates={states}
              name={states.order.userId.name}
              address={states.order.addressId}
              btnInfo={states.button}
              updateOrder={updateOrder}
            />
          )}
      </AnimatePresence>
    </>
  );
}
