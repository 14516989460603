import axios from "axios";
import moment from "moment-timezone";

// Set default timezone to Asia/Kolkata
moment.tz.setDefault("Asia/Kolkata");
const Base_Url = process.env.REACT_APP_BACKEND_URL;
/**
 * in order to generate new otp
 * @param {Object} : {url: base url , number: phone number}
 * @returns {Promise} : returns a promise that will be resolved with an object or rejects with an error
 */
const generate_otp = async ({ url, options }) => {
  try {
    // console.log(options);
    const res = await axios.post(`${url}/santa`, {
      options,
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return {
      isSuccess: false,
      isError: true,
      message: "wrong respose from the server",
      isExist: false,
      hash: null,
    };
  }
};

const checkOTPAndVerifyApi = async ({ options }) => {
  try {
    const res = await axios.post(`${Base_Url}/santa`, {
      options,
    });

    return res.data;
  } catch (error) {
    return {
      isSuccess: false,
      message: error.message,
    };
  }
};

const get_santa_details_api = async ({ options }) => {
  try {
    const res = await axios.post(`${Base_Url}/santa`, { options });
    return res.data;
  } catch (error) {
    console.log(error);
    return {
      isSuccess: false,
      isError: true,
      message: "error",
      santa: null,
    };
  }
};

const update_santa_api = async ({ options }) => {
  // const options = {
  //   queryObject: { _id: santaId },
  //   updateObject: { },
  //   projectionString:  "",
  //   tags: ["crud", "update"],
  // };
  const token = localStorage.getItem("ACCESS_TOKEN");
  try {
    const res = await axios.post(`${Base_Url}/santa`, {
      options: { ...options, token: token },
    });
    return res.data;
  } catch (error) {
    window.toast(error.message);
    console.log(error);
    return {
      isSuccess: false,
      isError: true,
      message: "error",
      santa: null,
    };
  }
};

const santaWalletCrudHandlerApi = async ({ options }) => {
  try {
    let newOption = options;
    const token = localStorage.getItem("ACCESS_TOKEN");
    newOption["token"] = token;
    const res = await axios.post(`${Base_Url}/santa`, { options: newOption });
    return res.data;
  } catch (error) {
    return {
      isSuccess: false,
      message: error.message,
    };
  }
};

const deleteUserPermanentlyApi = async ({ options }) => {
  try {
    const res = await axios.post(`${Base_Url}/santa`, { options });
    return res.data;
  } catch (error) {
    console.log(error);
    return {
      isSuccess: false,
    };
  }
};

// common api
const santa_api_with_options = async ({ options }) => {
  try {
    let _options = options;
    const token = localStorage.getItem("ACCESS_TOKEN");
    _options["token"] = token;
    const res = await axios.post(`${Base_Url}/santa`, { options: _options });
    return res.data;
  } catch (error) {
    console.log(error);
    return {
      isSuccess: false,
      isError: true,
      message: "error",
      santa: null,
    };
  }
};

const startExocallApi = async ({ santaNumber, userNumber, exophone }) => {
  try {
    let _options = {
      userNumber: userNumber,
      santaNumber: santaNumber,
      exophone: exophone,
      tags: ["user-santa-call"],
    };
    const token = localStorage.getItem("ACCESS_TOKEN");
    _options["token"] = token;

    const res = await axios.post(`${Base_Url}/santa`, { options: _options });
    return res.data;
  } catch (error) {
    return {
      isSuccess: false,
      message: error.message,
    };
  }
};

const preDefinedOptions = {
  santaDetailsGet: (santaId, projectionString = "") => {
    if (!santaId) {
      // get from local storage
      santaId = localStorage.getItem("SANTA_ID");
    }
    return {
      tags: ["crud", "get_santa_details"],
      queryObject: { _id: santaId },
      projectionString: projectionString,
    };
  },
  getWeeklyEarnings: (weeks) => {
    if (!weeks) {
      // if week number not present, add the current week number and last 3 weeks
      weeks = [
        { week: moment().isoWeek(), year: moment().year() },
        {
          week: moment().subtract(1, "weeks").isoWeek(),
          year: moment().subtract(1, "weeks").year(),
        },
        {
          week: moment().subtract(2, "weeks").isoWeek(),
          year: moment().subtract(2, "weeks").year(),
        },
        {
          week: moment().subtract(3, "weeks").isoWeek(),
          year: moment().subtract(3, "weeks").year(),
        },
        {
          week: moment().subtract(4, "weeks").isoWeek(),
          year: moment().subtract(4, "weeks").year(),
        },
      ];
    }
    return {
      tags: ["santaWeeklyEarnings"],
      weeks: weeks,
    };
  },
  updateSanta: (updateObject) => {
    const santaId = localStorage.getItem("SANTA_ID");
    return {
      queryObject: { _id: santaId },
      updateObject,
      projectionString: "",
      tags: ["crud", "update"],
    };
  },
};

const santaApi = async (options = {}) => {
  try {
    // adding the token inside the options
    const token = localStorage.getItem("ACCESS_TOKEN");

    // making the request
    const res = await axios.post(`${Base_Url}/santa`, { options, token });

    // returning the response
    return res.data;
  } catch (error) {
    console.error(error);
    return {
      isSuccess: false,
      message: error.message,
    };
  }
};

const batchedSantaApi = async (batches = []) => {
  try {
    const token = localStorage.getItem("ACCESS_TOKEN");

    // making the request
    const res = await axios.post(`${Base_Url}/santa`, {
      batches,
      token,
    });

    // returning the response
    return res.data;
  } catch (error) {
    console.error(error);
    return {
      isSuccess: false,
      message: error.message,
    };
  }
};

export {
  generate_otp,
  checkOTPAndVerifyApi,
  get_santa_details_api,
  update_santa_api,
  santaWalletCrudHandlerApi,
  deleteUserPermanentlyApi,
  santa_api_with_options,
  startExocallApi,
  santaApi,
  preDefinedOptions,
  batchedSantaApi,
};
