import { santaApi } from "../../../../api/santaApi";
import TeamSelectionModal from "./TeamSelectionModal";

const acceptOrder = async ({
  states,
  setStates,
  refetchSantaDetails,
  santa,
  workingSantasIds = [],
  isOptIn = false,
}) => {
  try {
    console.log("acceptOrder", states, santa, workingSantasIds, isOptIn);
    const res = await santaApi({
      tags: ["order", "liveOrderRequestHandler"],
      orderId: states.order._id,
      santaId: santa._id,
      action: "accept",
      workingSantasIds,
      isOptIn,
    });

    if (res.isSuccess) {
      await refetchSantaDetails();
      setStates((p) => ({ ...p, isOrderAcceptedPage: true }));
    } else {
      window.toast("Oops! something went wrong", "error");
    }
  } catch (error) {
    console.error(error);
  }
};

export const handleOrderAccept = async ({
  states,
  santa,
  refetchSantaDetails,
  setStates,
  order,
}) => {
  try {
    // get the verified team members

    const verifiedTeamMembers = santa?.myTeam.filter((curr) => {
      const {
        onboardingStatus,
        OTPVerificationFromLoginPage,
        isOnboardingComplete,
      } = curr.santa.profileDraftDetails;

      const isVerified =
        onboardingStatus === "approved" &&
        OTPVerificationFromLoginPage &&
        isOnboardingComplete &&
        curr.isVerified;

      return isVerified;
    });

    if (verifiedTeamMembers.length > 0) {
      window.floatingModal(
        true,
        <TeamSelectionModal
          {...{
            verifiedTeamMembers,
            santa,
            order,
            states,
            acceptOrder,
            setStates,
            refetchSantaDetails,
          }}
        />,
        {
          position: "c",
          animation: "b-t",
          animationLength: "70vh",
        }
      );
    } else {
      await acceptOrder({
        states,
        setStates,
        refetchSantaDetails,
        santa,
        isOptIn: true,
      });
    }
  } catch (error) {
    console.error(error);
  }
};
