import React, { useMemo, useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { santaApi } from "../../../../api/santaApi";

export default function TeamSelectionModal({
  santa,
  order,
  refetchSantaDetails,
}) {
  const [states, setStates] = useState({
    selectedSantaIds: order.santaGroups?.santas
      ?.filter((curr) => !curr.tags.includes("optout"))
      ?.map((curr) => curr.santa?._id),
    selectedSanta: order.santaGroups?.santas
      ?.filter((curr) => !curr.tags.includes("optout"))
      ?.map((curr) => curr.santa),
    isSaving: false,
  });

  const verifiedTeamMembers = useMemo(() => {
    return santa?.myTeam.filter((curr) => {
      const {
        onboardingStatus,
        OTPVerificationFromLoginPage,
        isOnboardingComplete,
      } = curr.santa.profileDraftDetails;

      const isVerified =
        onboardingStatus === "approved" &&
        OTPVerificationFromLoginPage &&
        isOnboardingComplete &&
        curr.isVerified;

      return isVerified;
    });
  }, [santa]);

  const canAccept = useMemo(() => {
    return states.selectedSantaIds.length > 0;
  }, [states.selectedSantaIds]);

  const onAcceptOrder = async () => {
    setStates((prev) => ({ ...prev, isSaving: true }));
    try {
      const res = await santaApi({
        tags: ["order", "liveOrderRequestHandler"],
        orderId: order._id,
        santaId: santa._id,
        action: "accept",
        acceptedOrder: true,
        workingSantasIds: states.selectedSantaIds.filter(
          (_) => _ !== santa._id
        ),
        isOptIn: states.selectedSantaIds?.includes(santa._id) || false,
      });

      if (res.isSuccess) {
        await refetchSantaDetails();
      } else {
        window.toast("Oops! something went wrong", "error");
      }

      window.floatingModal(false);
    } catch (error) {
      console.error(error);
    }
    setStates((prev) => ({ ...prev, isSaving: false }));
  };

  return (
    <div className="bg-white rounded-xl border w-[22rem] max-w-[95vw] border-gray-200 flex-col">
      {/* header */}
      <div className="flex items-center justify-between px-4 py-3 border-b border-gray-200">
        <div className="font-semibold text-base">Who will go for work</div>
        <RxCross2
          onClick={() => {
            window.floatingModal(false);
          }}
          className="text-xl"
        />
      </div>

      {/* santa list */}
      <div className="flex flex-col max-h-[18rem] px-4 py-4 overflow-y-scroll">
        <div
          onClick={() => {
            if (!states.selectedSantaIds.includes(santa._id)) {
              setStates((prev) => ({
                ...prev,
                selectedSantaIds: [...prev.selectedSantaIds, santa._id],
                selectedSanta: [...prev.selectedSanta, santa],
              }));
            } else {
              setStates((prev) => ({
                ...prev,
                selectedSantaIds: prev.selectedSantaIds.filter(
                  (id) => id !== santa._id
                ),
                selectedSanta: prev.selectedSanta.filter(
                  (cs) => cs._id !== santa._id
                ),
              }));
            }
          }}
          className="flex  flex-col "
        >
          <div className="flex relative justify-between gap-2 items-center w-full ">
            <div className="flex gap-2 items-start w-full ">
              <img
                src={
                  santa.personalDetails?.image ||
                  "/images/santaPicPlaceholder.jpg"
                }
                alt={santa.personalDetails?.name}
                className="rounded-full w-[2.5rem] border border-gray-200  bg-[white] aspect-square"
              />

              <div className="flex flex-col">
                <div className="font-[500] text-sm flex items-center gap-2">
                  {santa.personalDetails?.name?.split(" ")[0] || "-"}{" "}
                  <span className="bg-blue-100 text-[#2136d4] px-2 py-0.5 rounded-md text-[10px] leading-3">
                    You
                  </span>
                </div>
                <div className="text-[10px] text-gray-500 font-[400]  ">
                  {santa.personalDetails?.phoneNumber}
                </div>
              </div>
            </div>
            {states.selectedSantaIds.includes(santa._id) ? (
              <div className="rounded-full blue-gradient p-2.5"></div>
            ) : (
              <div className="rounded-full border border-gray-300 bg-gray-100 p-2.5 "></div>
            )}
          </div>
        </div>
        <VerifiedMembersList {...{ states, setStates, verifiedTeamMembers }} />
      </div>
      <div className="w-full px-4 py-2.5">
        <div
          onClick={() => {
            if (canAccept) {
              if (states.isSaving) return;
              onAcceptOrder();
            } else {
              window.toast("Please select atleast one santa to proceed");
            }
          }}
          className={`blue-gradient ${
            !states.isSaving && canAccept
              ? "mirror-Animation before:w-[20px]"
              : "opacity-[0.80]"
          } w-full text-center py-2.5 text-base font-semibold rounded-full `}
        >
          {states.isSaving ? (
            <div
              className="loader w-[1rem] "
              style={{
                borderTopColor: "white",
              }}
            ></div>
          ) : (
            "Save team"
          )}
        </div>
      </div>
    </div>
  );
}

const VerifiedMembersList = ({ verifiedTeamMembers, setStates, states }) => {
  return verifiedTeamMembers.map((currMember, idx) => {
    const currSanta = currMember.santa;

    if (!currSanta) return null;

    const personalDetails = currSanta?.personalDetails;

    const isSelecteSanta = states.selectedSantaIds.includes(currSanta._id);

    return (
      <div
        key={idx}
        onClick={() => {
          if (!isSelecteSanta) {
            setStates((prev) => ({
              ...prev,
              selectedSantaIds: [...prev.selectedSantaIds, currSanta._id],
              selectedSanta: [...prev.selectedSanta, currSanta],
            }));
          } else {
            setStates((prev) => ({
              ...prev,
              selectedSantaIds: prev.selectedSantaIds.filter(
                (id) => id !== currSanta._id
              ),
              selectedSanta: prev.selectedSanta.filter(
                (cs) => cs._id !== currSanta._id
              ),
            }));
          }
        }}
        className="flex  flex-col   border-t border-gray-200 pt-3 mt-3 "
      >
        <div className="flex relative justify-between gap-2 items-center w-full ">
          <div className="flex gap-2 items-start w-full ">
            <img
              src={personalDetails?.image || "/images/santaPicPlaceholder.jpg"}
              alt={personalDetails?.name}
              className="rounded-full w-[2.5rem] border border-gray-200  bg-[white] aspect-square"
            />

            <div className="flex flex-col">
              <div className="font-[500] text-sm ">
                {personalDetails?.name.split(" ")[0] || "-"}
              </div>
              <div className="text-[10px] text-gray-500 font-[400]  ">
                {personalDetails?.phoneNumber}
              </div>
            </div>
          </div>
          {isSelecteSanta ? (
            <div className="rounded-full blue-gradient p-2.5"></div>
          ) : (
            <div className="rounded-full border border-gray-300 bg-gray-100 p-2.5 "></div>
          )}
        </div>
      </div>
    );
  });
};
